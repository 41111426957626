<template>
  <div>
    <van-nav-bar style="color: #000000" title="新手教程" left-arrow @click-left="onClickLeft" />

    <div class="van-hairline--surround div-border">
      <p style=" text-align: center;">产品可见，销售才能轻松实现，私域流量，才是我的量</p>
      <p
        style="text-indent:2em;"
      >写线往人置行加干接应把政千个治数容果交如化界号量主思更层身只般自除里权被工除众感位原知文你文约点没个济来细又进况任区同片再程设写先厂极他革规比。指问月张当当许术道历保许基什约阶设精有完具很华局百种很装我上思总维难报增他重适时记根至三无主又金改什离与。六太么并队性美林有他自始组安织话改新放得号加带边军展大消角长光调文二集农划社年界。他严维看采精观用想大原即须等入从众表院心水只按厂存识军往构火型际选厂复关知政术价长住身际白育图转价识转系族热习温对组。增点文志走统本车动科之住王准路划声同须规社便七参…</p>
    </div>

    <div class="van-hairline--surround div-border">
      <p style=" text-align: center;">产品可见，销售才能轻松实现，私域流量，才是我的量</p>
      <p
        style="text-indent:2em;"
      >写线往人置行加干接应把政千个治数容果交如化界号量主思更层身只般自除里权被工除众感位原知文你文约点没个济来细又进况任区同片再程设写先厂极他革规比。指问月张当当许术道历保许基什约阶设精有完具很华局百种很装我上思总维难报增他重适时记根至三无主又金改什离与。六太么并队性美林有他自始组安织话改新放得号加带边军展大消角长光调文二集农划社年界。他严维看采精观用想大原即须等入从众表院心水只按厂存识军往构火型际选厂复关知政术价长住身际白育图转价识转系族热习温对组。增点文志走统本车动科之住王准路划声同须规社便七参…</p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  methods: {
    onClickLeft() {
      Toast("返回");
    }
  }
};
</script>

<style>
.div-border {
  margin: 20px;
  padding: 10px 0px;
  background: #ffffff;
}
</style>